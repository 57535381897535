import { useRouter } from "next/router";
import { useEffect } from "react";

import { useAuth } from "contexts/AuthUserContext";

import * as S from "./styles";
import Button from "components/Button";

const Login = () => {
  const {
    authUser,
    loadingGoogle,
    loadingFacebook,
    loadingApple,
    loginWithGoogle,
    loginWithFacebook,
    loginWithApple,
  } = useAuth();
  const router = useRouter();

  const isLoading = loadingGoogle || loadingFacebook || loadingApple;

  useEffect(() => {
    if (!isLoading && authUser) {
      router.push(`${router.query.return || "/"}`);
    }
  }, [authUser, isLoading]);

  return (
    <S.Wrapper>
      <S.CallIllustration>
        <img src="/img/illustration-login.svg" alt="Illustration" />
        <S.Title>Hungry, huh?</S.Title>
        <S.Description>
          We have
          <br /> a <strong>lot of options</strong>
          <br />
          inside this bag.
        </S.Description>
      </S.CallIllustration>

      <S.RightBox>
        <S.LoginBox>
          <img src="/img/bento-logo-header.svg" />

          <S.LoginTitle>
            <span>Sign in and</span>
            <br />
            make your choice
          </S.LoginTitle>

          <S.ButtonLogin
            variant="google"
            onClick={loginWithGoogle}
            loading={loadingGoogle}
            disabled={isLoading}
          >
            Sign in with Google
          </S.ButtonLogin>
          <S.ButtonLogin
            variant="facebook"
            onClick={loginWithFacebook}
            loading={loadingFacebook}
            disabled={isLoading}
          >
            Sign in with Facebook
          </S.ButtonLogin>
          <S.ButtonLogin
            variant="apple"
            onClick={loginWithApple}
            loading={loadingApple}
            disabled={isLoading}
          >
            Sign in with Apple
          </S.ButtonLogin>

          <Button
            minimal
            onClick={() => router.push("/login-with-email")}
            loading={loadingApple}
            disabled={isLoading}
          >
            Or sign in with e-mail
          </Button>
        </S.LoginBox>

        <S.Policies>
          Checkout our <a href="https://hello.bento.ky/policies">Policies</a>
        </S.Policies>
      </S.RightBox>
      <S.ImageBg />
    </S.Wrapper>
  );
};
export default Login;
