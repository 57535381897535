import Button from "components/Button";
import { lighten } from "polished";
import styled, { css } from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.main`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  position: relative;
  background: ${Theme.colors.sunsetOrange};
  background: linear-gradient(
    235deg,
    ${Theme.colors.sunsetOrange} 0%,
    #fba140 100%
  );

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const ImageBg = styled.div`
  position: absolute;
  left: 0;
  width: 60%;
  height: 100%;
  background: url("/img/shape-bg-login.svg") right center no-repeat;
  object-fit: contain;
`;

export const CallIllustration = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;

  > img {
    width: 250px;
    margin-bottom: 20px;
    object-fit: contain;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Title = styled.h1`
  font-family: "Quicksand";
  font-size: 54px;
  font-weight: bold;
  color: #fff;
`;

export const Description = styled.p`
  margin-top: 20px;
  font-family: "Quicksand";
  font-size: 36px;
  color: ${Theme.colors.wildSand};
`;

export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
  margin-bottom: 35px;
  padding: 35px 20px 30px 20px;
  border-radius: 1px;
  box-shadow: 10px 12px 20px 0 rgba(149, 28, 28, 0.22);
  background-color: #fff;
  z-index: 1;

  > img {
    width: 100px;
  }

  @media (max-width: 768px) {
    width: 100%;
    min-width: 250px;
    padding-left: 45px;
    padding-right: 45px;
  }
`;

export const LoginTitle = styled.div`
  margin: 26px 24px 35px 26px;
  font-family: "Nunito";
  font-size: 28px;
  text-align: center;
  color: ${Theme.colors.sunsetOrange};

  > span {
    color: ${Theme.colors.tundora};
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const ButtonLogin = styled(Button)<{ variant: string }>`
  ${({ variant }) => {
    const colorButton = () => {
      if (variant === "google") return "#ff4b47";
      if (variant === "apple") return "#000";
      return "#3963c7";
    };

    return css`
      width: 288px;
      height: 48px;
      margin-bottom: 16px;
      padding: 14px 0;
      border-radius: 144px;
      font-family: "Nunito";
      font-size: 14px;
      font-weight: bold;
      line-height: 1.43;
      color: #fff;
      border: none;
      cursor: pointer;
      background-color: ${colorButton()};
      transition: background 0.2s ease-in-out;

      &:hover {
        background-color: ${lighten(0.08, colorButton())};
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;

        &:hover {
          background-color: ${lighten(0.08, colorButton())};
        }
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    `;
  }}
`;

export const Policies = styled.div`
  font-family: "Quicksand";
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #fff;

  a {
    color: #fff;
  }
`;
